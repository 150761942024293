<template>
	<div class="roll-info">
		<div class="Title ">
			<p > &nbsp;&nbsp;&nbsp;&nbsp;福&nbsp;&nbsp; 利&nbsp;&nbsp; 房&nbsp;&nbsp;&nbsp;&nbsp; </p>
			<div class="Title-lower"></div>
		</div>
		<div class="block roll-head">
			<h4 class="time" v-show="data.type == '1'">{{ data.start_time || data.end_time }}</h4>
			<h4 class="time" v-show="data.type == '2'">当前玩家数量: {{data.join_number}}/{{data.people_number}}</h4>
			<div class="header" :style="'background-image: url(' + data.user.avatar + ');'"></div>
			<h2 class="name">{{ data.name }}</h2>
			<div class="limit" v-show="data.type == '1'">
				确保您的账户至少充值${{
                    data.min_recharge
                }}，如果您遵循了此条件，您将获得这个房间参与资格。当抽奖的计时结束时，我们的系统会随机抽取中奖者。
			</div>
			<div class="limit" v-show="data.type == '2'">
				确保您的账户至少充值${{
			        data.min_recharge
			    }}，如果您遵循了此条件，您将获得这个房间参与资格。当房间的人数已满时，我们的系统会随机抽取中奖者。
			</div>
			<div class="MiaoShu" v-show="data.describe != '' ">{{data.describe}}</div>


			<!-- v-show="data.status == 1" -->
			<div class="btn"  v-show="data.status == 1"  @click="JoinHome()">
		
				{{userAdd?'已加入':'我要加入'}}
			</div>
		</div>
		<div class="block item-box" v-show="data.status == 2">
			<div class="Title Title-bg" style="margin-top: .4rem;">
				<p>获奖用户</p>
				<div class="Title-lower"></div>
			</div>
			<div class="info">
				共有{{ data.box_records.length }}件
			</div>
			<div class="awards-list">
				<div v-for="(value, key) in data.box_records" class="line" :key="key">
					<div :class="'award'">
						<div class="price"><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{ value.bean }}</div>
						<div class="pic" :style="'background-image: url('+value.lv_bgImage+')'">
							<img :src="value.cover" alt="" />
						</div>
						<div class="name">{{ value.name }}</div>
					</div>
					<div class="user">
						<div class="header">
							<img :src="value.user? value.user.avatar : ''" alt="" />
						</div>
						<div class="name">{{ value.user?value.user.name:"" }}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="block item-box">
			<div class="Title Title-bg" style="margin-top: .4rem;">
				<p>活动奖池</p>
				<div class="Title-lower"></div>
			</div>
			<div class="info">
				共有{{ data.box_records.length }}件，总价值：${{
                    data.award_bean
                }}
			</div>
			<div class="item-list">
				<div v-for="(value, key) in data.box_records" :class="'item'" :key="key">
					<div class="price"><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{ value.bean }}</div>
					<div class="pic" :style="'background-image: url('+value.lv_bgImage+')'">
						<img :src="value.cover" alt="" />
					</div>
					<div class="name">{{ value.name }}</div>
				</div>
			</div>
		</div>
		<div class="block item-box">
			<div class="Title Title-bg" style="margin-top: .4rem;">
				<p>参与玩家</p>
				<div class="Title-lower"></div>
			</div>
			<div class="info">共{{ data.join_number }}人</div>
			<div class="user-list">
				<div class="item" v-for="(value, key) in data.room_users" :key="key">
					<div class="header">
						<img :src="value.avatar" alt="" />
					</div>
					<div class="name">{{ value.name }}</div>
				</div>
			</div>
		</div>
		<transition name="fade">
			<div class="input-box" v-show="input">
				<div class="box">
					<div class="pwd">
						<el-input placeholder="请输入密码" v-model="pwd" show-password></el-input>
					</div>
					<div class="btn close" @click="input = false">取消</div>
					<div class="btn verify" @click="JoinHome(pwd)">确认</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import {
		SRollInfo,
		SJoinHome
	} from "@/network/server";
	import myMixin from "@/utils/mixin";
	import {
		mapState
	} from 'vuex'
	export default {
		mixins: [myMixin],
		data() {
			return {
				data: {
					name: "",
					start_time: "",
					end_time: "",
					status: 0,
					box_records: [],
					user: {
						avarar: "",
					},
					room_users: [],
					join_number: 0,
					award_bean: 0,
				},
				input: false,
				pwd: "",
				//是否已加入
				userAdd:false
			};
		},
		computed: {
		...mapState([
			'user',
			'IndividualsShow',
			'RegisterShow',
			'SignInShow'
		])
	},
		created() {
			this.RollInfo();
			setTimeout(()=>{
				let index = this.data.room_users.findIndex(item=>{
					return item.id == this.user.id
				})
				if(index != -1){
					this.userAdd = true
				}
			},500)
		},
		watch: {
			$route(to, from) {
				if (to.name == "room") {
					this.RollInfo();
				}
			},
		},
		methods: {
			RollInfo() {
				SRollInfo(this.$route.query.id).then((data) => {
					this.data = data;
					// console.log(data);
				});
			},
			JoinHome(pwd) {
				if (this.data.is_pwd && !pwd) {
					this.input = true;
					return;
				}
				SJoinHome(this.$route.query.id, pwd)
					.then((data) => {
						this.Success("加入成功");
						this.input = false;
					})
					.catch((err) => {
						this.Err(err.message);
						this.input = false;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s;
	}

	.fade-enter,
	.fade-leave-to {
		transform: translateX(3rem);
		opacity: 0;
	}

	.roll-info {
		padding-top: .4rem;
		.block {
			position: relative;
			// background: url(../../assets/images/Roll/ROLL_boxBack.png) no-repeat center;
			background-size: cover;
			margin: 0.3rem 0 0.2rem;
			padding: 0.02rem;
			/*overflow: hidden;*/

			.info {
				margin: .2rem auto;
				text-align: center;
			}
		}

		.roll-head {
			// height: 1.6rem;
			text-align: center;
			background-size: 100% 100%;
			padding: .5rem 0;
			background-image: url("../../assets/images/Roll/top-bg.png");
			.header {
				margin: 0.2rem;
				width: 1.6rem;
				height: 1.6rem;
				background-color: rgb(80, 80, 80);
				background-size: 100% 100%;
				border-radius: 50%;
				text-align: center;
				line-height: 1.2rem;
				font-size: 0.32rem;
				font-weight: bold;
				margin:  0 auto;
			}

			.name {

				margin: .2rem 0;
			}

			.limit {
				font-size: 0.16rem;
			}

			.MiaoShu {
				// height: 0.3rem;
				line-height: 0.3rem;
				font-size: 0.16rem;
				padding: 0 50px;
				// white-space: nowrap; //不换行
				// overflow: hidden; //超出隐藏
				// text-overflow: ellipsis; //变成...
				// margin: .2rem 0;
			}

			.time {

				font-size: 0.2rem;
				margin-bottom: .2rem;
			}

			.btn {
				margin: 0 auto;
				width: 3rem;
				height: .5rem;

				text-align: center;
				line-height: .5rem;
				color: #fff;
				font-size: .18rem;
				cursor: pointer;
				background-size: 100% 100%;
				background-image: url("../../assets/images/Roll/btn.png");
			}
		}

		.item-box {
			.awards-list {
				display: flex;
				flex-wrap: wrap;
				position: relative;
				.line {
					position: relative;
					width: 2rem;
					overflow: hidden;
					margin-right: .39rem;
					background-color: #131426;
					/*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
					background-size: 100% 100%;
					margin-bottom: .3rem;
					&:nth-child(6n){
						margin-right: 0;
					}
					.award {
						width: 2rem;

						text-align: center;
						background-size: 100% 100%;

						.pic{
							width: 100%;
							height: 1.2rem;
							background-repeat: no-repeat;
							background-size: 110%;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-bottom: .1rem;
							img {
								max-width: 80%;
								max-height: 80%;
							}
						}

						.name {
							width: 100%;
							padding: 0 .05rem;
							box-sizing: border-box;
							margin-bottom: .16rem;
							font-size: 0.5em;
						}

						.quality {
							width: 100%;
							font-size: 0.5em;
						}

						.price {
							font-size: 0.14rem;
							margin: 0.1rem;
							display: flex;
							justify-content: center;
							align-items: center;
							img{
								width: .2rem;
								height: .2rem;
								vertical-align: middle;
								margin: 0;
								margin-right: 0.03rem;
							}
						}
					}

					.user {
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-bottom: .12rem;
						.header {
							width: 0.3rem;
							height: 0.3rem;
							border-radius: 50%;
							overflow: hidden;
							margin-right: .05rem;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.name {
							font-size: 0.5em;
						}
					}
				}
			}

			@for $i from 1 through 5 {
				.roll-lv#{$i} {
					background-image: url(lv-img($i));
				}
			}

			.item-list {
				display: flex;
				flex-wrap: wrap;

				.item {
					width:2rem;
					margin-right: 0.39rem;
					text-align: center;
					background-size: 100% 100%;
					background-color: #131426;
					/*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
					background-size: 100% 100%;
					padding: .1rem 0;
					&:nth-child(6n){
						margin-right: 0;
					}
					margin-bottom: .39rem;
					.pic{
						width: 100%;
						height: 1.4rem;
						background-repeat: no-repeat;
						background-size: 110%;
						background-position: center;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-bottom: .1rem;
						img {
							max-width: 90%;
							max-height: 90%;
						}
					}


					.name {
						width: 100%;
						font-size: 0.5em;
						padding: 0 .05rem;
						box-sizing: border-box;
						margin-bottom: .16rem;
						margin-top: .1rem;
					}

					.quality {
						width: 100%;
						font-size: 0.5em;
					}

					.price {
						font-size: 0.14rem;
						margin: 0.1rem;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							width: .2rem;
							height: .2rem;
							vertical-align: middle;
							margin: 0;
							margin-right: .03rem;
						}
					}
				}
			}

			.user-list {
				display: flex;
				flex-wrap: wrap;

				.item {
					width: 1rem;
					margin: 0.19rem;
					text-align: center;

					.header {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0.1rem;
						border-radius: 50%;
						overflow: hidden;

						img {
							width: 0.8rem;
							height: 0.8rem;
						}
					}

					.name {
						width: 100%;
						font-size: 0.5em;
					}
				}
			}
		}

		.input-box {
			position: fixed;
			top: 3rem;
			right: 0.3rem;
			box-shadow: 0.02rem 0.02rem 0.1rem rgb(0, 0, 0);
			z-index: 200;

			.box {
				width: 2.8rem;
				height: 1.3rem;
				background: rgb(39, 39, 39);
				border-radius: 0.03rem;

				.pwd {
					position: absolute;
					width: 2.4rem;
					height: 0.4rem;
					margin: 0.2rem;
				}

				@include input(rgb(218, 218, 218));

				.btn {
					position: absolute;
					box-sizing: border-box;
					@include btn(1.1rem,
					0.4rem,
					rgb(218, 218, 218));
				}

				.close {
					font-size: 16px;
					left: 0.2rem;
					top: 0.7rem;
				}

				.verify {
					font-size: 16px;
					right: 0.2rem;
					top: 0.7rem;
				}
			}
		}
	}
</style>
